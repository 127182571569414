<template>
    <div class="ele-body">
        <el-card shadow="never">
            <el-tabs v-model="active" class="user-info-tabs" @tab-click="tabClick">
                <!--货品列表-->

                <el-tab-pane label="货品列表" name="course_data">
                    <el-form :model="table.where" class="ele-form-search d-flex mt-20"
                             @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
                        <el-form-item label="排列顺序:" label-width="76px" class="w-200">
                            <el-select v-model="table.where.sort">
                                <el-option value="1" label="全部"></el-option>
                                <el-option value="2" label="销量排行"></el-option>
                                <el-option value="3" label="收益排名"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="店铺类型:" label-width="82px" class="w-200">
                            <el-select v-model="table.where.type">
                                <el-option value="0" label="全部"></el-option>
                                <el-option value="5" label="叫餐"></el-option>
                                <el-option value="3" label="超市"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商家姓名:" label-width="82px" class="w-200">
                            <el-input v-model="table.where.name" placeholder="" clearable/>
                        </el-form-item>
                        <el-form-item label="手机号:" label-width="82px" class="w-200">
                            <el-input v-model="table.where.name" placeholder="" clearable/>
                        </el-form-item>
                        <el-form-item label="店铺名称:" label-width="82px" class="w-200">
                            <el-input v-model="table.where.store_name" placeholder="" clearable/>
                        </el-form-item>
                        <el-form-item label="省:" label-width="35px" class="w-170">
                            <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
                                       placeholder='请选择省'>
                                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="市:" label-width="35px" class="w-150">
                            <el-select v-model="table.where.cid"
                                       @change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市'>
                                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="县/区:" label-width="62px" class="w-200">
                            <el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()">
                                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                                       class="ele-btn-icon ml-20">搜索
                            </el-button>
                            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                                    highlight-current-row class='mt-20' :stripe=true>
                        <template slot-scope="{index}">
                            <el-table-column type="selection" width="45" align="center" fixed="left"/>
                            <el-table-column type="index" :index="index" label="编号" width="60" align="center"
                                             fixed="left" show-overflow-tooltip/>
                            <el-table-column prop="area_name" label="地区" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="shop_logo" label="店铺头像" show-overflow-tooltip>
                                <template slot-scope="{row}">
																	<el-image style="width:25px;height:25px;" :src="row.shop_logo" fit="cover" :preview-src-list="[row.shop_logo]">
																		<div slot="error" class="image-slot">
																			<i class="el-icon-picture-outline"></i>
																		</div>
																	</el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="type_name" label="店铺类型" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="category_num" label="货品分类(种)" show-overflow-tooltip
                                             min-width="120"/>
                            <el-table-column prop="goods_num" label="货品总数(个)" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="sold_num" label="上架量(个)" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="sold_out_num" label="下架量(个 )" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="month_sale_num" label="本月销量(个)" show-overflow-tooltip
                                             min-width="120"/>
                            <el-table-column prop="month_sale_price" label="本月收益(元)" show-overflow-tooltip
                                             min-width="160"/>
                            <el-table-column prop="sold_num" label="总销量(个)" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="update_time" label="货品更新时间" show-overflow-tooltip min-width="160"/>
                            <el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
                                <template slot-scope="{row}">
                                    <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
                                             v-if="permission.includes('sys:merchant_productlist:view')">查看详情
                                    </el-link>
                                </template>
                            </el-table-column>
                        </template>
                    </ele-data-table>
                </el-tab-pane>
                <!--审核列表-->
                <el-tab-pane label="审核列表" name="course_study">
                    <el-form :model="table1.where" class="ele-form-search d-flex mt-20"
                             @keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
                        <el-form-item label="店铺类型:" label-width="76px" class="w-200">
                            <el-select v-model="table1.where.type">
                                <el-option value="0" label="全部"></el-option>
                                <el-option value="5" label="叫餐"></el-option>
                                <el-option value="3" label="超市"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="商家姓名:" label-width="82px" class="w-200">
                            <el-input v-model="table1.where.name" placeholder="" clearable/>
                        </el-form-item>
                        <el-form-item label="店铺名称:" label-width="82px" class="w-200">
                            <el-input v-model="table1.where.store_name" placeholder="" clearable/>
                        </el-form-item>
                        <el-form-item label="省:" label-width="35px" class="w-170">
                            <el-select v-model="table1.where.pid" @change="handleChangeProv(table1.where.pid)"
                                       placeholder='请选择省'>
                                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="市:" label-width="35px" class="w-150">
                            <el-select v-model="table1.where.cid"
                                       @change="handleChangeCity(table1.where.cid),$forceUpdate()" placeholder='请选择市'>
                                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
                            <el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()">
                                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                           :label="option.name"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
                                       class="ele-btn-icon">搜索
                            </el-button>
                            <el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
                        </el-form-item>
                    </el-form>
                    <ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
                                    highlight-current-row class='mt-20'>
                        <template slot-scope="{index}">
                            <el-table-column type="selection" width="45" align="center" fixed="left"/>
                            <el-table-column type="index" :index="index" label="编号" width="60" align="center"
                                             fixed="left" show-overflow-tooltip/>
                            <el-table-column prop="aname" label="地区" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="shop_logo" label="店铺头像" show-overflow-tooltip>
                                <template slot-scope="{row}">
																	<el-image style="width:25px;height:25px;" :src="row.shop_logo" fit="cover" :preview-src-list="[row.shop_logo]">
																		<div slot="error" class="image-slot">
																			<i class="el-icon-picture-outline"></i>
																		</div>
																	</el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="type_name" label="店铺类型" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="category_name" label="货品分类(种)" show-overflow-tooltip
                                             min-width="120"/>
                            <el-table-column prop="name" label="货品名称 " show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="images" label="货品图片" show-overflow-tooltip>
                                <template slot-scope="{row}">
                                        <el-image style="width:25px;height:25px;" :src="row.images" fit="cover" :preview-src-list="[row.images]">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="price" label="货品售价(元)" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="original_price" label="货品原价(元)" show-overflow-tooltip
                                             min-width="160"/>
                            <el-table-column prop="stock" label="上架库存(个)" show-overflow-tooltip min-width="120"/>
                            <el-table-column prop="unit" label="商品规格" show-overflow-tooltip min-width="160"/>
                            <el-table-column label="提交时间" prop="add_time" show-overflow-tooltip min-width="160"/>
                            <el-table-column prop="status" label="状态" show-overflow-tooltip min-width="120"/>
                            <el-table-column label="操作" width="130px" align="center" :resizable="false" fixed="right">
                                <template slot-scope="{row}">
                                    <el-link @click="product_success(row)" icon="el-icon-success" type="primary"
                                             :underline="false" v-if="permission.includes('sys:merchant_productlist:pass')">通过
                                    </el-link>
                                    <el-link @click="product_error(row)" icon="el-icon-error" type="danger"
                                             :underline="false" v-if="permission.includes('sys:merchant_productlist:reject')">驳回
                                    </el-link>
                                </template>
                            </el-table-column>
                        </template>
                    </ele-data-table>
                </el-tab-pane>
            </el-tabs>
        </el-card>
    </div>
</template>

<script>
    import {
        mapGetters
    } from "vuex";
    // import TinymceEditor from '@/components/TinymceEditor'
    export default {
        name: "SysLimitNumbers",
        // components: {TinymceEditor},
        data() {
            return {
                table: {
                    url: '/Storegoods/index', //货品列表
                    where: {}
                }, // 设置列表表格配置
                table1: {
                    url: '/Storegoods/audit_store_goods_list',  //审核列表
                    where: {}
                },
                choose: [], // 表格选中数据
                showEdit: false, // 是否显示表单弹窗
                showBusiness: false,
                showRunning: false,
                showPickup: false,
                showPackFee: false,
                editForm: {},
                editForm1: {},
                editForm2: {},
                editForm3: {},
                editRules: { // 表单验证规则
                },
                accordList: [{
                    id: 1,
                    name: '咱县打车用户端'
                },
                    {
                        id: 2,
                        name: '咱县打车司机端'
                    },
                    {
                        id: 3,
                        name: '咱县打车跑腿端'
                    },
                    {
                        id: 4,
                        name: '咱县打车商家端'
                    },
                    {
                        id: 5,
                        name: '可蚁点用户端'
                    },
                    {
                        id: 6,
                        name: '可蚁点司机端'
                    },
                    {
                        id: 7,
                        name: '可蚁点跑腿端'
                    },
                    {
                        id: 8,
                        name: '可蚁点商家端'
                    },
                    {
                        id: 9,
                        name: '可蚁点小程序端'
                    },
                ],
                provArr: [],
                cityArr: [],
                districtArr: [],
                // provArr1:[],
                // cityArr1:[],
                // districtArr1:[],
                active: 'course_data',
                activeEdit: 'run-driver',
                pid: '',
                cid: '',
                aid: '',
                pid1: '',
                cid1: '',
                aid1: '',
                change: 1,
                orderId: 1,
            }
        },
        created() {
            this.$http.get('/common/province_list').then(res => {
                let data = JSON.parse(res.data)
                this.provArr = data
                this.provArr1 = data
            })

        },
        computed: {
            ...mapGetters(["permission"]),

            editContent() {
                return {
                    menubar: false,
                    // file_picker_callback: this.file_picker_callback,
                    skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
                        '/tinymce/skins/ui/oxide',
                    content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
                        '/tinymce/skins/content/default/content.css'
                };
            }
        },
        mounted() {
        },
        methods: {
            edit(row) {
                // var orderId=1
                // this.$router.push({path: `/system/merchant/order_evaluation?orderId=${orderId}` })
                if (row.id) {
                    this.$router.push({
                        path: '/system/merchant/order_evaluation',
                        query: {
                            orderId: row.store_id,
                            area_name: row.area_name,
                            store_name: row.store_name
                        }
                    })
                }
            },
            product_success(row) {
                this.$prompt('确认通过该审核？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then((value) => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/Storegoods/audit_store_goods', {
                        ids: row.id,
                        status: 1,
                        reason: value.value
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.activeName = 'second';
                            this.$refs.table.reload();
                            this.$refs.table1.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                })


            },
            product_error(row) {

                this.$prompt('确认驳回该审核？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then((value) => {
                    const loading = this.$loading({
                        lock: true
                    });
                    this.$http.post('/Storegoods/audit_store_goods', {
                        ids: row.id,
                        status: 3,
                        reason: value.value
                    }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.activeName = 'second';
                            this.$refs.table.reload();
                            this.$refs.table1.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                })
            },
            tabClick(row) {
                if (row.name == 'course_data') {
                    this.change = 1
                } else if (row.name == 'course_study') {
                    this.change = 2
                } else if (row.name == 'course_pick') {
                    this.change = 3
                } else if (row.name == 'course_orderPack') {
                    this.change = 4
                }
            },
            /**
             *选择省
             **/
            handleChangeProv(e) {
                /** 获取被选省份的pid**/
                let pid = ''
                this.provArr.forEach(function (item) {
                    if (item.pid == e) {
                        pid = item.pid

                    }

                })
                /** 根据被选省份的pid获取省市下面的市**/
                this.$http.post('/common/city_list', {
                    pid: pid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.cityArr = data
                    /** 选择省份清空市县**/
                    if (this.change == 2) {
                        this.table1.where.cid = ''
                        this.table1.where.aid = ''
                    }


                })
            },
            /**
             *选择市
             **/
            handleChangeCity(e) {
				if(e){
                /** 获取被选市的cid**/
                let cid = ''
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', {
                    cid: cid
                }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    if (this.change == 2) {
                        this.table1.where.aid = ''
                    }
                })
								}else{
									this.table1.where.pid = ''
									this.table1.where.aid = ''
								}
            },

            /**
             * 商家服务协议显示编辑
             */
            editBusiness(row) {
                this.showBusiness = true
                this.editForm = row
            },
            editRunning(row) {
                this.showRunning = true
                this.editForm1 = row
            },
            editPickup(row) {
                this.showPickup = true
                this.editForm2 = row
            },
            editPackFee(row) {
                this.showPackFee = true
                this.editForm3 = row
            },

            /**
             * 刪除(批量刪除)
             */
            remove(row) {
                // 单个删除
                this.$confirm('确定要删除吗?', '提示', {
                    type: 'warning'
                }).then(() => {
                    // const loading = this.$loading({lock: true});
                    this.$http.post('/user/delete', {
                        id: row.id
                    }).then(res => {
                        // loading.close();
                        if (res.data.code === 0) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        // loading.close();
                        this.$message.error(e.message);
                    });
                })

            },

            /**
             * 保存编辑
             */
            save() {
                if (this.pid) {
                    this.editForm.pid = this.pid
                } else if (this.cid) {
                    this.editForm.cid = this.cid
                } else if (this.aid) {
                    this.editForm.aid = this.aid
                }
                // const loading = this.$loading({lock: true});
                this.$http.post('/Legwork/legworkSave', this.editForm).then(res => {
                    // loading.close();
                    if (res.data.code === 0) {
                        this.showEdit = false;
                        this.$message({
                            type: 'success',
                            message: res.data.msg
                        });
                        this.$refs.table.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    // loading.close();
                    this.$message.error(e.message);
                });

                // });
            },

        }
    }
</script>

<style scoped>
    .ele-block >>> .el-upload,
    .ele-block >>> .el-upload-dragger {
        width: 100%;
    }

    .driverForm /deep/ .el-form-item__content {
        margin-left: 0 !important
    }

    /deep/ .el-tabs__nav {
        margin-left: 20px
    }

    .inputWidth {
        width: 220px;
        margin-right: 10px;
        margin-bottom: 20px
    }
</style>
